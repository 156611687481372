import styled from 'styled-components';

function App() {
  return (
    <PageDiv>
      <InfoDiv>
        <Title>AQUALIOS</Title>
        <SubTitle>graphic design + web dev</SubTitle>

        <Link href="mailto:contact@aqualios.com?subject=Hello!" target="_blank"><Email>contact@aqualios.com</Email></Link>
      </InfoDiv>
    </PageDiv>
 );
}

export default App;

const PageDiv = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  font-family: "ShadowsIntoLight-Regular";
`;

const InfoDiv = styled.div`
`;


const Title = styled.p`
  display: flex;
  font-size: 12vh;
  margin: 0;
  padding: 0;
  cursor: default;
`;

const SubTitle = styled.p`
  display: flex;
  font-size: 4vh;
  margin-top: -3vh;
  justify-content: center;
  cursor: default;
`;

const Email = styled.p`
  display: flex;
  font-size: 3vh;
  margin-top: 30vh;
  justify-content: center;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    color: grey;
  }
`

const Link = styled.a`
  text-decoration: none;
  color: black;
`;

